function PDFView(props) {
  return (
    <div class="col-lg-3 col-md-4 col-sm-6">
      <a
        href={props.url}
        className="card border-dark mb-3 p-4 shadow"
        target="_blank"
      >
        <img src={props.src} alt="pdf" className="card-img-top" />
        <div className="card-body">
          <h5 className="card-title">{props.title}</h5>
        </div>
      </a>
    </div>
  );
}

export default PDFView;
