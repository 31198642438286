import { Gallery } from "react-grid-gallery";
function PhotoGallery() {
  const images = [
    {
      src: "https://drive.google.com/uc?export=view&id=1I7TGDwYU4JB9GOjhuog552GvxQS_qNNm",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1CBXjtu-6I7bbp6A-tlx5EwHsbzHnOITm",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1LpMp4K9449iRrzVpPf2ZrWbkqoN6WEUF",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1dzg8c_NCRzkmwingKP5N61FW3x2yrXvM",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1r94Pp40lxrdOu-bBQ6M0KoPxLOuwpzYH",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1zUnNagTUvr21ktbiC9cGLPMSIDVQtoJQ",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1vKIW-NVzG143_fmMGwk2g1YtFeSNpyHE",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1TdOpF-Sg7JE2V7v9HfGihCilnkqeCMAS",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1Ug-AKY3BU2Ezixxa5I5gOcjGPn-3BvTp",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1VIun-miEkAf0Bhh__BL2rJReEK1Rk7LY",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1Nl3E3IMwMyeo2LvYNXxpyHap82DJ8F5N",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1GENdbhZzWp60_x_HUroVlPAvW87vxGyZ",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1nwGJURBkUtgrqf_44eXKmF1jFglz3kp6",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1eh1zc19bNNK62OGDYCSv69D_y7TMo2cD",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1MGYtK5-oFBoQs4BwEV11rGK95ir4ZYdZ",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1j4HDsYob-40Ulun9p4s4Hoaq8KxbjUBg",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1bTmwfFXmmo4AnY3X5peasO4oOCHPLSlW",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1o8FKBh_fiL0pBDb8VYZ2YCTWbanNkLkB",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=18zA2-seWrU20AsNS0ExTR5UPCl1unRXG",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1kgLshWfLVf5CVql52iVNZhPS9_nvUtfE",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1hMWT2G6U3fup0zSgssxNRj6oIgAVVb2B",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1W1LMch1zCpSREy8v3lOLdVsfVh9TW65M",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1zGqsLns3s32K9XgiUWpYo7dRgRKPhdAr",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1R91TOYiNSB5VsSOoqUHP9Bngv1zx7TBC",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1Mnv5XkGfXTNCiwoBt6kWjfM1icmNYkYk",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1Zf7j_cT2cLELUBYRXMMmlludIGs1Jib3",
      width: 320,
      height: 174,
    },
    {
      src: "https://drive.google.com/uc?export=view&id=1Q5ic5y1jnZhb1cLEwLPjdfp26APk6gVL",
      width: 320,
      height: 174,
    },
  ];

  return (
    <div>
      <Gallery images={images} />
    </div>
  );
}

export default PhotoGallery;
