import React from "react";
import "./css/home_styles.css";
function Footer() {
  return (
    <footer class="bg-dark text-white pt-4">
      <div class="container text-left">
        <div class="row">
          <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3 text-start">
            <h5 class="text-uppercase mb-4 fw-bold">Universal Smart Academy</h5>
            <p>Bandukey, Maneybong,</p>
            <p>Uttarey, West Sikkim, PIN: 737113</p>
            <p>Affiliation No: 1820142</p>
            <p>School Code No: 37401</p>
          </div>
          <div class="col-md-2 col-lg-3 col-xl-3 mx-auto mt-3 text-start">
            <h5 class="text-uppercase mb-4 fw-bold">Contact Us</h5>
            <p>pmmusa333@gmail.com</p>
            <p>37401@cbseshiksha.in</p>
            <p>Phone: 7407320413, 9609853125</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
