import React from "react";
import Disclosure from "../res/mandatory_disclosure.pdf";
import "./css/mandatory_disclosure_style.css";
import GeneralInformationTable from "./mandatory_disclosure_components/general_info_table";
import StaffTable from "./mandatory_disclosure_components/staff_table";
import Download from "./Download";

function MandatoryDisclosure() {
  return (
    <div class="mandatory_disclosure">
      <h1>
        <b>Mandatory Disclosure</b>
      </h1>
      <Download
        url={Disclosure}
        downloadTitle={"Mandatory Disclosure"}
        buttonTitle={"Download Mandatory Disclosure"}
      />
      <h3>General Information</h3>
      <GeneralInformationTable />
      <h3>Staff Information</h3>
      <StaffTable />
    </div>
  );
}

export default MandatoryDisclosure;
