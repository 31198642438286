function StaffTable() {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th>Information</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>PRINCIPAL</td>
            <td>MRS SHANGREIWON KEISHING</td>
          </tr>
          <tr>
            <td>2</td>
            <td>TOTAL NUMBER OF TEACHERS</td>
            <td>18</td>
          </tr>
          <tr>
            <td>3</td>
            <td>NUMBER OF TGTs</td>
            <td>09</td>
          </tr>
          <tr>
            <td>4</td>
            <td>NUMBER OF PETs</td>
            <td>01</td>
          </tr>
          <tr>
            <td>5</td>
            <td>NUMBER OF PRTs</td>
            <td>09</td>
          </tr>
          <tr>
            <td>6</td>
            <td>NUMBER OF MANDATORY TRAINING QUALIFIED TEACHERES</td>
            <td>10</td>
          </tr>
          <tr>
            <td>8</td>
            <td>NUMBER OF NTTs</td>
            <td>02</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default StaffTable;
