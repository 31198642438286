import calendar from "../res/Calendar.pdf";
import Download from "./Download";
import "./css/calendar_styles.css";
function Calendar() {
  return (
    <div className="div_calendar">
      <h1>
        <b>School Calendar</b>
      </h1>
      <Download
        url={calendar}
        downloadTitle={"School Calendar"}
        buttonTitle={"Download School Calendar"}
      />
      <iframe
        src={calendar}
        title="School Calendar"
        height={1000}
        width={600}
      ></iframe>
    </div>
  );
}

export default Calendar;
