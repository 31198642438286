import React from 'react';
import MissionTitle from './mission_components/TitleSection.js';
import MissionDiscription from './mission_components/MissionDescriptionSection.js';
function Mission() {
    return (
        <div>
            <MissionTitle /> 
            <MissionDiscription />
        </div>);
}

export default Mission;