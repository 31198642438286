import React from "react";

function Admissions() {
  return (
    <div className="d-flex flex-column flex-sm-row p-4 ">
      <div
        class="container p-4 me-2 mw-100"
        style={{ backgroundColor: "#E3F2FD" }}
      >
        <h1 class=" fw-bold fs-1 text-start mb-4" style={{ color: "#1A237E" }}>
          How to Apply
        </h1>
        <p class="lead text-start">
          Admissions commences from LKG to 10th standard. For more information
          please contact us.
        </p>
      </div>
      <div
        class="container p-4 ms-sm-2 mt-sm-0 mt-4 mw-100"
        style={{ backgroundColor: "#90CAF9" }}
      >
        <h1 class=" fw-bold fs-1 text-start mb-4" style={{ color: "#1A237E" }}>
          Tuition
        </h1>
        <p class="lead text-start">
          Tuition may vary from year to year. Please contact administration to
          get all the latest details.
        </p>
        <a
          href="/contact-us"
          class="fw-bold float-start"
          style={{ color: "#1A237E" }}
        >
          Contact Us.
        </a>
      </div>
    </div>
  );
}

export default Admissions;
