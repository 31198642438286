import React from "react";

function MissionTitle() {
  return (
    <section>
      <div className="container">
        <h1
          className="fw-bold text-start"
          style={{ fontSize: "35px", color: "#009879" }}
        >
          Our Mission
        </h1>
        <div className="container" style={{ backgroundColor: "#009879" }}>
          <p className="text-white fs-3 lead p-5 text-start">
            Our mission is to provide transformative education and empower
            students of remote Uttarey by fostering knowledge, creativity, and
            connectivity.
          </p>
        </div>
      </div>
    </section>
  );
}

export default MissionTitle;
