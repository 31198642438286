import { motion } from "framer-motion";
import React from "react";
import DetailCard from "./DetailCard";
import extraco_img from "../../res/card_0.JPG";
import group_img from "../../res/card_2.JPG";
import assembly_img from "../../res/card_3.JPG";
import nepali_img from "../../res/card_1.jpg";
function AcademicDetail() {
  const animationDuration = 1.1;
  const eduDetails = [
    {
      title: "CBSE Affiliated",
      body: "Affiliated with the Central Board of Secondary Education",
      id: 1,
      img: group_img,
    },

    {
      title: "English Medium",
      body: "English is used as the medium of instruction",
      id: 2,
      img: assembly_img,
    },

    {
      title: "Culture",
      body: "Focus on tribal and local languages such as Limbu and Nepali",
      id: 3,
      img: nepali_img,
    },

    {
      title: "Extracurriculars",
      body: "Yoga, Tae-Kown-Do, Painting, Chess, Platation program etc",
      id: 4,
      img: extraco_img,
    },
  ];
  return (
    <section className="mb-4">
      <div className="container">
        <div className="row">
          <div className="col mb-2 mb-sm-0">
            <motion.div
              animate={{ scale: 1, opacity: 1 }}
              initial={{ scale: 0, opacity: 0 }}
              transition={{ ease: "easeOut", duration: animationDuration }}
            >
              <DetailCard detail={eduDetails[0]} />
            </motion.div>
          </div>
          <div className="col mb-2 mb-sm-0">
            <motion.div
              animate={{ scale: 1 }}
              initial={{ scale: 0 }}
              transition={{ ease: "easeOut", duration: animationDuration }}
            >
              <DetailCard detail={eduDetails[1]} />
            </motion.div>
          </div>
          <div className="col mb-2 mb-sm-0">
            <motion.div
              animate={{ scale: 1 }}
              initial={{ scale: 0 }}
              transition={{ ease: "easeOut", duration: animationDuration }}
            >
              <DetailCard detail={eduDetails[2]} />
            </motion.div>
          </div>
          <div className="col mb-2 mb-sm-0">
            <motion.div
              animate={{ scale: 1 }}
              initial={{ scale: 0 }}
              transition={{ ease: "easeOut", duration: animationDuration }}
            >
              <DetailCard detail={eduDetails[3]} />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AcademicDetail;
