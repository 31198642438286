import React from "react";
import AcademicDetail from "./academics_components/AcademicDetailSection";

function Academics() {
  return (
    <div class="mt-4">
      <AcademicDetail />
    </div>
  );
}

export default Academics;
