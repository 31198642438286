function GeneralInformationTable() {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Sl.No</th>
            <th>Information</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>SCHOOL NAME</td>
            <td>PHUL MOTI MEM UNI SMART ACADEMY BANDUKEY W SM</td>
          </tr>
          <tr>
            <td>2</td>
            <td>AFFILIATION NO.</td>
            <td>1820142</td>
          </tr>
          <tr>
            <td>3</td>
            <td>SCHOOL CODE</td>
            <td>37401</td>
          </tr>
          <tr>
            <td>4</td>
            <td>COMPLETE ADDRESS WITH PIN CODE</td>
            <td>BANDUKEY, MANEYBONG, UTTAREY, WEST SIKKIM, PIN: 737113</td>
          </tr>
          <tr>
            <td>5</td>
            <td>PRINCIPAL NAME</td>
            <td>MRS SHANGREIWON KEISHING</td>
          </tr>
          <tr>
            <td>6</td>
            <td>SCHOOL EMAIL ID</td>
            <td>pmmusa333@gmail.com</td>
          </tr>
          <tr>
            <td>8</td>
            <td>CONTACT DETAILS</td>
            <td>7407320413/9609853125</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default GeneralInformationTable;
