import React from "react";
import teacherImg from "../../res/mission.JPG";
function MissionDiscription() {
  return (
    <section className="mb-4">
      <div className="container">
        <div className="d-flex flex-sm-row flex-column">
          <img src={teacherImg} width={700} alt="School teacher" />
          <div
            style={{ backgroundColor: "#009879" }}
            className="align-items-center p-4"
          >
            <p className="text-white lead fs-3 text-start my-auto">
              We aspire to provide an hollistic learning experince.
              <br />
              That aims to bring the best of Western education while preserving
              Sikkimese culture.
              <br />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MissionDiscription;
