import PDFView from "./school_certificate_components/PDFView";
import first from "../res/School Documents/1.png";
import second from "../res/School Documents/2.png";
import third from "../res/School Documents/3.png";
import fourth from "../res/School Documents/4.png";
import fifth from "../res/School Documents/5.png";
import sixth from "../res/School Documents/6.png";
import seventh from "../res/School Documents/7.png";
import eighth from "../res/School Documents/8.png";
import ninth from "../res/School Documents/9.png";
import tenth from "../res/School Documents/10.png";
import eleventh from "../res/School Documents/11.png";
import twelfth from "../res/School Documents/12.png";
import thirteeth from "../res/School Documents/13.png";
import fourteenth from "../res/School Documents/14.png";
import cbse from "../res/School Documents/CBSE_Affiliation.png";
import deedTrust from "../res/School Documents/deed_of_trust.png";
import renewal from "../res/School Documents/renewal.png";

import "./css/registration_styles.css";
function SchoolCertificate() {
  return (
    <div className="container d-flex flex-column justify-content-center align-items-center">
      <h1>
        <b>School Documents</b>
      </h1>
      <div className="row justify-content-center">
        <PDFView
          url={
            "https://drive.google.com/file/d/17tJeuCQPPIODD9EmgrEdfQH0xptPudJq/view?usp=sharing"
          }
          src={first}
          title={
            "CBSE Regular Affiliation Certificate vide No. AFF/ 1820142/05/5994-97, Dated: 13-14/10/2005"
          }
        />
        <PDFView
          url={
            "https://drive.google.com/file/d/17sAR12t3fuLCuiVV_aDgIH3HNny5TIYy/view?usp=sharing"
          }
          src={second}
          title={
            "State Recognition Certificate Registration as Private Educational Institution from the HRDD, Goverment of Sikkim, vide No.74/S/W/2009, Dated: 09/09/2009"
          }
        />
        <PDFView
          url={
            "https://drive.google.com/file/d/11poQQbv3m5neh3f0k8y6LKSQMwfx7Jaj/view?usp=sharing"
          }
          src={renewal}
          title={
            "Renewal of Provisional Registration Certificate from the Department of Education, Government of Sikkim, vide No.74/S/W/09/09/2009, Dated: 23/06/2023"
          }
        />
        <PDFView
          url={
            "https://drive.google.com/file/d/1WRwDlyF0gW40i3AO9j04ATRUYJrNXJFH/view?usp=sharing"
          }
          src={cbse}
          title={
            "Extension of General Affiliation up to Secondary Level, vide No. CBSE/1820142/EX-02377-2425/2024-25/, Dated: 04/07/2023"
          }
        />
        <PDFView
          url={
            "https://drive.google.com/file/d/17ml5BvI4oxRkNvFZ8vSONhCv3pg0c89N/view?usp=sharing"
          }
          src={third}
          title={
            "Self-Certification Proforma, Category of Application: Permission to change Name of School/Society/Trust/Company (as per status of school)"
          }
        />
        <PDFView
          url={
            "https://drive.google.com/file/d/1TAwNBCmR0_-QMSg03fj0jG6bAt2B1w44/view?usp=drive_link"
          }
          src={deedTrust}
          title={
            "Certificate of Registration of Trust Deed, vide No. 19/SDM/JORT(S), Dated: 01/04/2023"
          }
        />
        <PDFView
          url={
            "https://drive.google.com/file/d/17i79XcF2ZkPTuRkK-jar7Na5KXRt1iVa/view?usp=sharing"
          }
          src={fourth}
          title={
            "NOC for Change of School Name from Gram Prashasan Kendra, 22 - Maneybong Gram Panchayat Unit, West Sikkim, Dated: 27/04/2023"
          }
        />
        <PDFView
          url={
            "https://drive.google.com/file/d/17guA4RZb9T3dpC4ePNB-NK7Rwvn9Zkfr/view?usp=sharing"
          }
          src={fifth}
          title={
            "Fire Safety NOC from the Office of the Fire Station Officer, Gyalshing Fire Station Sikkim Fire & Emergency Service, Gyalshing 737111, vide No. 75/14/GY/GFS/W/2023, Dated: 24/04/2023"
          }
        />
        <PDFView
          url={
            "https://drive.google.com/file/d/17VSmWDaHUJvwEGf-A5ZRevgnQ4-Bst7a/view?usp=sharing"
          }
          src={sixth}
          title={
            "Building Safety Certificate, vide No. 20/Bldgs/Div/W, Dated: 04/05/2023"
          }
        />
        <PDFView
          url={
            "https://drive.google.com/file/d/17aacurqmKsly6C7WnK76cVn0Myzti5tG/view?usp=sharing"
          }
          src={seventh}
          title={
            "Drinking Water Safety Certificate, vide No. 299/22MGDU/WS, Dated: 25/04/2023"
          }
        />
        <PDFView
          url={
            "https://drive.google.com/file/d/17_tS4s31dfaxR_bCbFMIxBG4f3b39Mcv/view?usp=sharing"
          }
          src={eighth}
          title={
            "Charitable Voluntary Organization from Gram Prashasan Kendra, vide No. 300/22MGDU/WS"
          }
        />
        <PDFView
          url={
            "https://drive.google.com/file/d/17Z5MzRpshiAp7TdEvOcPB1WQ8khMy3_D/view?usp=sharing"
          }
          src={ninth}
          title={
            "Charitable Voluntary Organization from the Principal, PMMUSA, vide No. 214(a)/PMMUSA/BANDUKEY(W)"
          }
        />
        <PDFView
          url={
            "https://drive.google.com/file/d/17U9jHWcghBGa-lzXevgFQOg16OCNYKiy/view?usp=sharing"
          }
          src={tenth}
          title={
            "No Banking Facility & EPG Office Availability Certificate, vide No. 28/BAC/DTM, Dated: 20/05/2023"
          }
        />
        <PDFView
          url={
            "https://drive.google.com/file/d/17T7HVl61-74clkpegSDp-wVBDATV0eUG/view?usp=sharing"
          }
          src={eleventh}
          title={"Affidavit for Non-Proprietary in Character and Nature."}
        />
        <PDFView
          url={
            "https://drive.google.com/file/d/17TA8zxvO5VUDH7fvr0qNdViHHZMVKNKb/view?usp=sharing"
          }
          src={twelfth}
          title={
            "Affidavit for Non-Blood Relattion in between the Chairman and the Secretary of the School."
          }
        />
        <PDFView
          url={
            "https://drive.google.com/file/d/17QIVKW2jJ7Mepgu2nUf_S5MYLL0ZLFkJ/view?usp=sharing"
          }
          src={thirteeth}
          title={"Fee Structure 2023 Onwards"}
        />
        <PDFView
          url={
            "https://drive.google.com/file/d/17QIVKW2jJ7Mepgu2nUf_S5MYLL0ZLFkJ/view?usp=sharing"
          }
          src={thirteeth}
          title={"Fee Structure 2023 Onwards"}
        />
        <PDFView
          url={
            "https://drive.google.com/file/d/17wSdIEDYav6CyM1d8g2bQSj-0w8NT1b9/view?usp=sharing"
          }
          src={fourteenth}
          title={"Board Exam Results Since 2016"}
        />
      </div>
    </div>
  );
}

export default SchoolCertificate;
