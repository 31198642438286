import "./App.css";
import NavBar from "./components/NavBar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Admissions from "./components/Admissions";
import Home from "./components/Home";
import Mission from "./components/Mission";
import Academics from "./components/Academics";
import MandatoryDisclosure from "./components/MandatoryDisclosure";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Calendar from "./components/Calendar";
import SchoolCertificate from "./components/SchoolCertificate";
import PhotoGallery from "./components/Gallery";

function App() {
  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL}>
        <NavBar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/admissions" element={<Admissions />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/academics" element={<Academics />} />
          <Route
            path="/mandatory-disclosure"
            element={<MandatoryDisclosure />}
          />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/school-documents" element={<SchoolCertificate />} />
          <Route path="/gallery" element={<PhotoGallery />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
