function Download(props) {
  return (
    <a
      href={props.url}
      download={props.downloadTitle}
      target="_blank"
      rel="noreferrer"
    >
      <button>{props.buttonTitle}</button>
    </a>
  );
}

export default Download;
